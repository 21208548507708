.ProductsHeader {
  .mask {
    pointer-events: none;
    height: theme('fontSize.120');

    @media screen and (min-width: theme('screens.lg')) {
      height: theme('fontSize.180');
    }

    &-text {
      animation: ProductsHeaderTicker infinite linear;
      animation-duration: 20s;
    }
  }
}

@keyframes ProductsHeaderTicker {
  0% {
    transform: translate3d(-25%, 0, 0);
    visibility: visible;
  }

  100% {
    transform: translate3d(-75%, 0, 0);
  }
}
