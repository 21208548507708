.wysiwyg {
    strong {
        @apply tw-font-bold;
    }

    ul {
        @apply tw-list-disc tw-pl-6;
    }

    ol {
        @apply tw-list-decimal tw-pl-6;
    }

    a {
        @apply tw-text-brand tw-underline tw-underline-offset-4 hover:tw-text-gray-800 tw-transition-colors tw-duration-300 tw-ease-in-out;
    }

    .wysiwyg-columns {
        @apply xl:tw-columns-2;
    }

    .wysiwyg-highlight {
        @apply tw-py-3 md:tw-py-5 tw-font-bold tw-border-t tw-max-w-2xl tw-text-brand tw-border-current;

        &:last-of-type {
            @apply tw-border-b;
        }
    }
}
