/* cyrillic-ext */
@font-face {
    font-family: 'Sofia Sans Condensed';
    font-style: italic;
    font-weight: 1 1000;
    font-display: swap;
    src: url(../fonts/r05KGKVS5aVKd567NYXawnFKJaTtoAuLnIcNlt79fl5HegUSTg.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
    font-family: 'Sofia Sans Condensed';
    font-style: italic;
    font-weight: 1 1000;
    font-display: swap;
    src: url(../fonts/r05KGKVS5aVKd567NYXawnFKJaTtoAuLnIcNltf9fl5HegUSTg.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek */
@font-face {
    font-family: 'Sofia Sans Condensed';
    font-style: italic;
    font-weight: 1 1000;
    font-display: swap;
    src: url(../fonts/r05KGKVS5aVKd567NYXawnFKJaTtoAuLnIcNltD9fl5HegUSTg.woff2) format('woff2');
    unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}

/* latin-ext */
@font-face {
    font-family: 'Sofia Sans Condensed';
    font-style: italic;
    font-weight: 1 1000;
    font-display: swap;
    src: url(../fonts/r05KGKVS5aVKd567NYXawnFKJaTtoAuLnIcNlt39fl5HegUSTg.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Sofia Sans Condensed';
    font-style: italic;
    font-weight: 1 1000;
    font-display: swap;
    src: url(../fonts/r05KGKVS5aVKd567NYXawnFKJaTtoAuLnIcNltP9fl5HegU.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
    font-family: 'Sofia Sans Condensed';
    font-style: normal;
    font-weight: 1 1000;
    font-display: swap;
    src: url(../fonts/r05EGKVS5aVKd567NYXawnFKJaTtoAuLnIcFptH3eXxFSgQ.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
    font-family: 'Sofia Sans Condensed';
    font-style: normal;
    font-weight: 1 1000;
    font-display: swap;
    src: url(../fonts/r05EGKVS5aVKd567NYXawnFKJaTtoAuLnIcMptH3eXxFSgQ.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek */
@font-face {
    font-family: 'Sofia Sans Condensed';
    font-style: normal;
    font-weight: 1 1000;
    font-display: swap;
    src: url(../fonts/r05EGKVS5aVKd567NYXawnFKJaTtoAuLnIcLptH3eXxFSgQ.woff2) format('woff2');
    unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}

/* latin-ext */
@font-face {
    font-family: 'Sofia Sans Condensed';
    font-style: normal;
    font-weight: 1 1000;
    font-display: swap;
    src: url(../fonts/r05EGKVS5aVKd567NYXawnFKJaTtoAuLnIcGptH3eXxFSgQ.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Sofia Sans Condensed';
    font-style: normal;
    font-weight: 1 1000;
    font-display: swap;
    src: url(../fonts/r05EGKVS5aVKd567NYXawnFKJaTtoAuLnIcIptH3eXxF.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
    font-family: 'Sofia Sans Semi Condensed';
    font-style: italic;
    font-weight: 1 1000;
    font-display: swap;
    src: url(../fonts/46k7laPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURsWYE_UaTj8cIvrLS.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
    font-family: 'Sofia Sans Semi Condensed';
    font-style: italic;
    font-weight: 1 1000;
    font-display: swap;
    src: url(../fonts/46k7laPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURsWYE9EaTj8cIvrLS.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek */
@font-face {
    font-family: 'Sofia Sans Semi Condensed';
    font-style: italic;
    font-weight: 1 1000;
    font-display: swap;
    src: url(../fonts/46k7laPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURsWYE80aTj8cIvrLS.woff2) format('woff2');
    unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}

/* latin-ext */
@font-face {
    font-family: 'Sofia Sans Semi Condensed';
    font-style: italic;
    font-weight: 1 1000;
    font-display: swap;
    src: url(../fonts/46k7laPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURsWYE_kaTj8cIvrLS.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Sofia Sans Semi Condensed';
    font-style: italic;
    font-weight: 1 1000;
    font-display: swap;
    src: url(../fonts/46k7laPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURsWYE8EaTj8cIvg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
    font-family: 'Sofia Sans Semi Condensed';
    font-style: normal;
    font-weight: 1 1000;
    font-display: swap;
    src: url(../fonts/46k9laPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURsW408kyUrcU4vw.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
    font-family: 'Sofia Sans Semi Condensed';
    font-style: normal;
    font-weight: 1 1000;
    font-display: swap;
    src: url(../fonts/46k9laPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURsWc08kyUrcU4vw.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek */
@font-face {
    font-family: 'Sofia Sans Semi Condensed';
    font-style: normal;
    font-weight: 1 1000;
    font-display: swap;
    src: url(../fonts/46k9laPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURsWA08kyUrcU4vw.woff2) format('woff2');
    unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}

/* latin-ext */
@font-face {
    font-family: 'Sofia Sans Semi Condensed';
    font-style: normal;
    font-weight: 1 1000;
    font-display: swap;
    src: url(../fonts/46k9laPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURsW008kyUrcU4vw.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Sofia Sans Semi Condensed';
    font-style: normal;
    font-weight: 1 1000;
    font-display: swap;
    src: url(../fonts/46k9laPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURsWM08kyUrcU.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.sofia-sans-semi-condensed {
    font-family: "Sofia Sans Semi Condensed", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.sofia-sans-condensed {
    font-family: "Sofia Sans Condensed", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
}