.HomeHeader {
  .mask {
    pointer-events: none;
    height: theme('fontSize.180');

    @media screen and (min-width: theme('screens.md')) {
      height: theme('fontSize.210');

      @media screen and (min-height: 530px) {
        height: theme('fontSize.240');
      }
    }

    @media screen and (min-width: theme('screens.xl')) {
      height: theme('fontSize.240');

      @media screen and (min-height: 740px) {
        height: theme('fontSize.360');
      }
    }

    &-background {
      mix-blend-mode: lighten;
    }

    &-text {
      animation: HomeHeaderTicker infinite linear;
      animation-duration: 30s;
      font-size: theme('fontSize.180');

      @media screen and (min-width: theme('screens.md')) {
        font-size: theme('fontSize.210');

        @media screen and (min-height: 530px) {
          font-size: theme('fontSize.240');
        }
      }

      @media screen and (min-width: theme('screens.xl')) {
        font-size: theme('fontSize.240');

        @media screen and (min-height: 740px) {
          font-size: theme('fontSize.360');
        }
      }
    }
  }
}

@keyframes HomeHeaderTicker {
  0% {
    transform: translate3d(-25%, 0, 0);
    visibility: visible;
  }

  100% {
    transform: translate3d(-75%, 0, 0);
  }
}