:root {
    --cc-primary-color: theme('colors.brand');
    --cc-text-color: theme('colors.black');
    --cc-text-color-headline: theme('colors.black');
    --cc-modal-box-background: theme('colors.white'); // background of content
    --cc-border-radius: 1px;
    --cc-border-color: theme('colors.gray.300');
    --cc-switch-bg-inactive: theme('colors.gray.300');
    --cc-background: rgba(
        21,
        34,
        41,
        0.85
    ); // background for modal, body-before-element
    --cc-btn-primary-hover-bg: theme('colors.gray.300');
    --cc-btn-default-hover-bg: theme('colors.gray.300');
}

.CookieConsentModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--cc-background);
    z-index: 50;
    display: none;
    overflow-x: hidden;
    overflow-y: auto;

    &.show {
        display: block;
    }

    .consent-outer {
        padding: 15vh 0 20vh;

        @media screen and (min-width: theme('screens.md')) {
            padding: 15vh 0 10vh;
        }
    }

    .consent-wrapper {
        background-color: var(--cc-modal-box-background);
        border-radius: var(--cc-border-radius);
        color: var(--cc-text-color);
        padding: 2rem 3rem;
        position: relative;
        z-index: 150;
        left: 50%;
        width: 100%;
        max-width: 320px;
        transform: translate(-50%, 0);

        @media screen and (min-width: 480px) {
            max-width: 440px;
        }

        @media screen and (min-width: 768px) {
            max-width: 500px;
        }

        @media screen and (min-width: 1024px) {
            max-width: 620px;
        }

        @media screen and (min-width: 1280px) {
            max-width: 680px;
        }

        @media screen and (min-width: 1340px) {
            max-width: 720px;
        }

        .Editor {
            a {
                @apply tw-underline tw-decoration-brand tw-decoration-2 tw-font-medium;

                &:hover {
                    @apply tw-decoration-green tw-text-green;
                }
            }
        }
    }
}

.CookieConsentModal,
.CookieConsentBrick {
    .script-list {
        display: flex;
        flex-direction: column;
        border-top: 1px solid var(--cc-border-color);
        width: 100%;
        margin-top: 2rem;

        &-item {
            display: block;
            width: 100%;

            &.is-open {
                svg {
                    transform: rotate(180deg);
                }

                .script-item-info {
                    display: block;
                }
            }
        }
    }

    .script-item {
        &-btn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 1rem 0;
            border-bottom: 1px solid var(--cc-border-color);

            &:focus span,
            &:active span {
                color: var(--cc-primary-color);
            }

            span {
                color: var(--cc-text-color-headline);
                font-weight: bold;
                font-size: 1.25rem;
            }
        }

        &-info {
            padding: 1rem;
            display: none;
            font-size: 0.75rem;
            background: var(--cc-border-color);
        }
    }

    .script-actions {
        display: flex;
        flex-direction: column;
        margin-top: 1.5rem;

        &-btn {
            border-radius: var(--cc-border-radius);
            text-align: center;
            user-select: none;
            background-color: transparent;
            border: 1px solid transparent;
            @apply tw-underline tw-decoration-brand tw-decoration-2 tw-font-medium;


            &:hover {
                @apply tw-decoration-green tw-text-green;

            }

            &.is-primary {
                padding: 0.625rem 1.25rem;
                background-color: var(--cc-primary-color);
                color: white;

                &:hover {
                    @apply tw-decoration-0 tw-decoration-transparent;
                    background: linear-gradient(91.32deg, #5E9F37 29.29%, #1A6F2A 75.55%);
                }
            }

            &.is-outlined {
                border-color: var(--cc-primary-color);
                color: var(--cc-primary-color);

                &:hover {
                    background-color: var(--cc-primary-color);
                    color: white;
                }
            }
        }

        @media screen and (min-width: 768px) {
            flex-direction: row;
            justify-content: flex-end;
        }
    }

    .CookiesSwitch {
        position: relative;
        display: block;
        cursor: pointer;

        input {
            -webkit-appearance: none;
            -webkit-tap-highlight-color: transparent;
            display: block;
            width: 4rem;
            height: 2rem;
            font-size: 2em;
            border-color: transparent;
            background-color: var(--cc-switch-bg-inactive);
            background-clip: padding-box;
            color: var(--cc-switch-bg-inactive);
            border-radius: 99px;
            vertical-align: middle;
            transition: all 0.25s linear 0.25s;

            &:disabled {
                cursor: not-allowed;
                opacity: 0.7;
            }

            &::before {
                // @apply tw-shadow-md;
                content: '';
                position: absolute;
                top: 2px;
                left: 2px;
                bottom: 2px;
                right: 50%;
                background-color: white;
                border-radius: 99px;
                background-clip: padding-box;
                z-index: 2;
                transform-origin: right center;
                animation: switch-off-transform 0.2s ease-out forwards;
            }

            &:checked {
                color: white;
                background-color: var(--cc-primary-color);
                border-color: transparent;

                &::before {
                    transform-origin: left center;
                    animation: switch-on-transform 0.2s ease-out forwards;
                }
            }

            &:focus {
                box-shadow: inset 0 0 0 2px var(--cc-text-color);
            }
        }
    }
}

@keyframes switch-on-transform {
    0% {
        transform: translate(0) scaleX(1);
    }
    25% {
        transform: translate(0) scaleX(1.33);
    }
    100% {
        transform: translate(100%) scaleX(1);
    }
}

@keyframes switch-off-transform {
    0% {
        transform: translate(100%) scaleX(1);
    }
    25% {
        transform: translate(100%) scaleX(1.33);
    }
    100% {
        transform: translate(0) scaleX(1);
    }
}

body.modal-active {
    position: relative;
    height: 100vh;
    overflow: hidden;
}
