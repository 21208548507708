.Navigation {
    @apply tw-border-b-2 tw-border-white tw-duration-500;
    transition-property: border, padding;
    z-index: 9999;

    .wrap {
        @apply tw-transition-all tw-duration-500;
    }

    &.sticky {
        @apply tw-border-brand md:tw-border-white;
    }

    .desktop-list {
        a {
            &.active,
            &:hover {
                @apply tw-underline tw-underline-offset-8;
            }
        }
    }

    .mobile-list {
        a {
            @apply tw-relative;

            &::before {
                @apply tw-absolute tw-left-0 tw-duration-300 tw-transition-opacity tw-opacity-0;
                transform: translateX(-140%);
                content: "→";
            }
            &.active,
            &:hover {
                &::before {
                    @apply tw-opacity-100;
                }
            }
        }
    }

    .toggle {
        @apply tw-border tw-border-brand tw-transition-all tw-duration-500;

        &::before,
        &::after {
            @apply tw-border-b tw-border-white tw-w-6/12 tw-transition-transform tw-duration-300 tw-absolute tw-top-1/2 tw-left-1/2;
            height: 1px;
            content: "";
        }

        &::before {
            transform: translate3D(-50%, -350%, 0);
        }

        &::after {
            transform: translate3D(-50%, 350%, 0);
        }

        &:hover {
            &::before {
                transform: translate3D(-50%, -250%, 0);
            }

            &::after {
                transform: translate3D(-50%, 250%, 0);
            }
        }

        &.active {
            @apply tw-border-white;

            &::before {
                transform: translate3D(-50%, -50%, 0) rotate(-45deg);
            }

            &::after {
                transform: translate3D(-50%, -50%, 0) rotate(45deg);
            }
        }
    }

    .user-menu-toggle-desktop {
        @apply tw-isolate tw-transition-colors tw-duration-300 tw-ease-in-out;

        &::after {
            content: "";

            @apply tw-absolute -tw-left-2 -tw-top-1 -tw-right-2 -tw-bottom-1 tw-bg-white tw-rounded-full tw-opacity-0 tw-transition-opacity tw-duration-300 tw-ease-in-out -tw-z-10;
        }

        &.active {
            @apply tw-text-brand;

            &::after {
                @apply tw-opacity-100;
            }
        }
    }
}
