.ContactForm {
    &[data-person-type=""],
    &[data-person-type="private"] {
        #contact_company_row {
            @apply tw-hidden;
        }
    }
}

.phone {
    &:before {
        @apply tw-absolute;
        content: url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28.893 27.0182C28.8199 26.4154 28.5004 25.9055 27.9916 25.5782L23.7175 22.2592C23.0695 21.6553 22.1247 21.5465 21.3547 21.991L20.4522 22.5123C19.7013 22.9458 18.8285 22.8818 17.859 22.322C15.7369 21.0969 13.8755 17.9028 13.8755 15.4872C13.8755 14.3752 14.2675 13.585 15.0404 13.1387L15.9812 12.5958C16.7464 12.1538 17.1258 11.2854 16.9326 10.4262L16.2306 5.20842C16.2019 4.60377 15.9198 4.07148 15.4342 3.70671C14.9397 3.33532 14.3377 3.2152 13.7389 3.36764L10.4148 4.21511C8.38701 4.54132 7.2497 5.80279 7.12591 7.86471L7.12554 7.87095C6.8728 12.9712 7.99799 17.6123 10.5654 22.059C13.4057 26.9789 16.8301 30.1722 21.6684 32.4138L21.6868 32.4218C22.0586 32.5787 22.5016 32.6974 22.992 32.6974C23.8248 32.6974 24.7935 32.3554 25.7798 31.2787L28.3387 28.6565C28.7707 28.2135 28.9676 27.6316 28.893 27.0182ZM27.4975 27.8344L24.9319 30.4635L24.9187 30.4778C23.7248 31.7856 22.7705 31.6001 22.1537 31.3418C17.5538 29.209 14.2939 26.1641 11.584 21.4705C9.13382 17.2262 8.05933 12.7978 8.30031 7.93156C8.39215 6.41846 9.13088 5.60479 10.6245 5.37226L10.6521 5.36785L14.0298 4.50679C14.2873 4.44103 14.5158 4.48695 14.7282 4.64638C14.9405 4.80581 15.0481 5.01262 15.0569 5.27822L15.0577 5.30797L15.771 10.6103L15.7751 10.6404L15.7824 10.6698C15.8702 11.0338 15.7178 11.3898 15.3934 11.5767L14.4526 12.1197C13.3058 12.7816 12.6997 13.9458 12.6997 15.4864C12.6997 18.3124 14.7932 21.9091 17.2713 23.3396C18.6121 24.1136 19.9155 24.1793 21.0406 23.5298L21.9432 23.0086C22.2694 22.8201 22.6555 22.8675 22.927 23.1287L22.9494 23.1504L27.3175 26.5421L27.3436 26.5583C27.5691 26.6986 27.6944 26.8951 27.7267 27.1589C27.7579 27.4237 27.683 27.6441 27.4975 27.8344Z' fill='%23222222'/%3E%3C/svg%3E%0A");
    }
}
