.Pagination {
  li {
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    .active {
      @apply tw-w-8 tw-h-8 tw-bg-brand tw-text-white tw-text-center;
    }
    a {
      span {
        @apply tw-text-2xl;
      }
    }
  }
}
