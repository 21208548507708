.tw-link {
  @apply tw-uppercase tw-underline tw-decoration-brand tw-decoration-2 tw-text-xl tw-font-medium tw-tracking-widest;

  &:hover {
    @apply tw-decoration-green tw-text-green;
  }

  &.tw-text-white {
    @apply tw-decoration-white;

    &:hover {
      @apply tw-decoration-yellow;
      color: theme('colors.yellow') !important;
    }
  }

  &.backLink {
    &:before {
      @apply tw-mr-2;
      content: url("data:image/svg+xml,%3Csvg width='18' height='15' viewBox='0 0 18 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.58878 15L0.270597 7.68182L7.58878 0.363637L8.84659 1.60511L3.66832 6.78338H17.0795V8.58026H3.66832L8.84659 13.7422L7.58878 15Z' fill='black'/%3E%3C/svg%3E%0A");
      width: 18px;
      height: 18px;
    }
  }
}

