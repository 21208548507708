.pagination {
    .underline-item {
        @apply tw-relative;

        &::after {
            content: "";

            @apply tw-absolute tw-left-1/2 -tw-translate-x-1/2 tw-w-3 tw-bg-brand tw-right-0 tw-h-px tw-bottom-2;
        }
    }
}
