.ProductTeaser {
  .product-card {
    picture {
      &:before {
        @apply tw-absolute tw-w-80 tw-h-80 tw-top-16 tw-right-0 tw-transition-all tw-duration-500 tw-opacity-0 lg:tw--right-8 xl:tw-right-8;
        transform: scale(0.9);
        content: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M44.2333 1.59502C45.9119 0.984059 47.7889 1.31503 49.1573 2.46326L75.3763 24.4636C76.7447 25.6119 77.3966 27.4029 77.0864 29.1621L71.1431 62.8686C70.8329 64.6278 69.6077 66.0879 67.9291 66.6988L35.7667 78.405C34.0881 79.0159 32.2111 78.685 30.8427 77.5367L4.62367 55.5364C3.25527 54.3881 2.60338 52.5971 2.91357 50.8379L8.85694 17.1314C9.16714 15.3722 10.3923 13.9121 12.0709 13.3012L44.2333 1.59502Z' fill='%231A6F2A'/%3E%3C/svg%3E%0A");
      }
    }

    &:hover {
      .tw-button-brand {
        @apply tw-text-white tw-bg-brand;
      }
      picture {
        &:before {
          @apply tw-opacity-100;
          transform: scale(1);
        }
      }
    }
    img {
      @apply tw-relative;
    }
  }
}
