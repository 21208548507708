.hive-mask {
    @apply tw-relative;

    .wrap {
        @apply tw-relative tw-block tw-w-full tw-leading-none;

        picture {
            @apply tw-absolute tw-block tw-z-10 tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-w-full tw-h-full;

            img {
                @apply tw-object-cover tw-w-full tw-h-full;
                mask-image: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 440 459' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M430.937 145.445L265.851 6.92147C262.156 3.82125 257.088 2.92763 252.556 4.57722L50.0473 78.2842C45.5151 79.9338 42.2072 83.876 41.3697 88.6258L3.94769 300.857C3.11017 305.606 4.87026 310.442 8.56496 313.542L173.651 452.066C177.346 455.167 182.414 456.06 186.946 454.411L389.454 380.704C393.987 379.054 397.295 375.112 398.132 370.362L435.554 158.131C436.392 153.381 434.631 148.546 430.937 145.445Z' fill='%23C4C4C4' stroke='white' stroke-width='7px'/%3E%3C/svg%3E%0A");
                mask-repeat: no-repeat;
                mask-size: contain;
                mask-position: top left;
            }
        }

        &:before {
            @apply tw-relative tw-block tw-pointer-events-none tw-w-full tw-z-30;
            aspect-ratio: 144 / 150;
            content: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 440 459' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M430.937 145.445L265.851 6.92147C262.156 3.82125 257.088 2.92763 252.556 4.57722L50.0473 78.2842C45.5151 79.9338 42.2072 83.876 41.3697 88.6258L3.94769 300.857C3.11017 305.606 4.87026 310.442 8.56496 313.542L173.651 452.066C177.346 455.167 182.414 456.06 186.946 454.411L389.454 380.704C393.987 379.054 397.295 375.112 398.132 370.362L435.554 158.131C436.392 153.381 434.631 148.546 430.937 145.445Z' fill='none' stroke='white' stroke-width='7px'/%3E%3C/svg%3E%0A");
        }
    }

    &:after, &:before {
        content: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 376 419' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg style='mix-blend-mode:screen' opacity='0.35' filter='url(%23filter0_f_921_2097)'%3E%3Cpath d='M335.467 126.722L333.02 295.687L185.467 378.05L40.3628 291.448L42.8107 122.482L190.363 40.1193L335.467 126.722Z' fill='%235E9F37'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f_921_2097' x='0.362854' y='0.119385' width='375.105' height='417.931' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='20' result='effect1_foregroundBlur_921_2097'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
        @apply tw-rotate-45 tw-absolute tw-w-44 tw-h-44 md:tw-w-80 md:tw-h-80;
    }

    &:before {
        @apply tw-top-0 tw-right-0 tw-translate-x-1/4 lg:tw--translate-y-1/4;
    }

    &:after {
        @apply tw-bottom-0 tw--translate-x-1/4;
    }
}