@layer base {
    html,
    body {
        font-family: theme('fontFamily.sofia-semi');
        scroll-behavior: smooth;
        font-size: 17px;
        scroll-padding-top: 100px;
    }
}

@layer utilities { 
    [x-cloak] { display: none !important; }

    .prevent-load-transition {
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -ms-transition: none !important;
        -o-transition: none !important;
        transition: none !important;
    }
}
