form {
    label,
    .label {
        @apply tw-text-sm;

        &.required {
            &::after {
                content: "*";
            }
        }

        p{
            @apply tw-inline;
        }
    }

    .terms-label{
        a{
            @apply tw-underline tw-text-brand;
        }

        p{
            @apply tw-inline;
        }
    }

    input,
    textarea,
    select {
        @apply tw-px-5 tw-py-3 tw-bg-white tw-leading-normal focus:tw-border-brand;

        &::placeholder {
            @apply tw-text-black;
        }
    }

    .checkbox {
        @apply tw-relative tw-pl-7;

        input {
            @apply tw-sr-only;
        }

        label {
            @apply tw-text-base;
            cursor: pointer;

            &::before,
            &::after {
                @apply tw-absolute tw-left-0 tw-block tw-w-5 tw-h-5;
                content: "";
            }

            &::before {
                @apply tw-border-2 tw-border-gray-800 tw-rounded;
            }

            &::after {
                @apply tw-top-0 tw-ease-in-out tw-duration-300 tw-rounded;
                background: url(../icons/checkmark.svg) center
                    theme("colors.brand") no-repeat;
                background-size: 70%;
                content: "";
                opacity: 0;
                visibility: hidden;
                transition-property: visibility, opacity;
            }
        }

        input:checked ~ label {
            &::after {
                opacity: 1;
                visibility: visible;
            }
        }

        &.required::after {
            content: " *";
        }

        &-custom {
            @apply tw-text-sm;
        }
    }

    .form-row {
        &.has-error {
            > label {
                color: theme("colors.red");
            }

            input,
            select,
            textarea {
                border: 2px solid theme("colors.red") !important;

                option[disabled],
                &::placeholder {
                    color: theme("colors.red") !important;
                }
            }

            .error-icon,
            .error-message {
                @apply tw-block;
            }

            .checkbox,
            .radio {
                label {
                    @apply tw-text-red;

                    &::before {
                        border: 2px solid theme("colors.red") !important;
                    }
                }
            }
        }

        .error-message {
            @apply tw-hidden tw-text-xs tw-mt-1;
            color: theme("colors.red");
        }

        .error-icon {
            @apply tw-hidden tw-absolute tw-text-red tw-top-3 tw-right-5 tw-w-6 tw-h-6;
            background: url(../icons/error.svg) center no-repeat;
            background-size: contain;
        }
    }

    .validation-container {
        .valid-indicator,
        .invalid-indicator {
            @apply tw-hidden;
        }

        &.valid {
            .valid-indicator {
                @apply tw-flex;
            }
        }

        &.invalid {
            .invalid-indicator {
                @apply tw-flex;
            }

            & > label {
                @apply tw-text-yellow;
            }

            .tw-border-gray-250 {
                border-color: theme("colors.yellow") !important;
            }
        }
    }
}
