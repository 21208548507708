.tw-button {
    @apply tw-inline-block tw-transition-colors tw-duration-500 tw-ease-in-out tw-relative tw-rounded-full tw-overflow-hidden;

    > span {
        @apply tw-relative tw-z-10;
    }

    &-sm {
        @apply tw-font-light tw-px-3.5 tw-py-1;
    }

    &-xl {
        @apply tw-text-xl tw-font-light tw-px-3 tw-py-2;
    }

    &-2xl {
        @apply tw-text-2xl tw-font-light tw-px-6 tw-py-3;
    }

    &-brand {
        @apply tw-bg-white tw-text-brand tw-border tw-border-brand tw-no-underline tw-rounded-3xl;

        &::after {
            @apply tw-bg-white;
        }

        &:hover {
            @apply tw-text-white tw-border-0;
            background: linear-gradient(91.32deg, #5E9F37 29.29%, #1A6F2A 75.55%);
        }

        &.alt {
            &:hover {
                background: none;
                @apply tw-bg-brand tw-border tw-border-brand;
            }
        }
    }

    &-green {
        @apply tw-bg-white lg:tw-bg-brand tw-border lg:tw-border-none tw-border-brand tw-text-gray-800 lg:tw-text-white tw-rounded-3xl;
    }

    &-alt {
        @apply tw-bg-white lg:tw-bg-gray-200 tw-border lg:tw-border-none tw-border-brand tw-text-gray-800 tw-rounded-3xl;
    }
}
