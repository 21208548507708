.EffectExperienceList {
  .list-item {
    &:last-child {
      @apply tw-border-b tw-border-gray-800;
    }
    picture {
      overflow: hidden;
      img {
        transition: transform .5s ease;
      }
      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
}
