.Footer {
  .footer-links {
    li {
      &::after {
        @apply tw-mx-1 sm:tw-mx-2;
        content: '|';
      }

      &:last-child {
        &::after {
          content: none;
        }
      }
    }
  }
}