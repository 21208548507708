body {
    .locked-content-indicator {
        @apply tw-hidden;
    }

    &.trial-lock {
        overflow: hidden !important;

        .trial-detail-content {
            @apply tw-pointer-events-none tw-select-none;

            [data-content-placeholder] {
                display: none !important;
            }
        }

        .locked-content-indicator {
            @apply tw-flex;
        }

        .scroll-navigation {
            @apply tw-hidden;
        }
    }
}
