.trial-create {
    .scroll-indicator {
        a {
            @apply tw-relative tw-isolate;

            &::after {
                content: "";

                @apply tw-absolute -tw-z-10 tw--left-2 tw-top-0 tw-right-0 tw-h-full tw-bg-white tw-rounded-l-md;

                transition: clip-path 300ms ease-in-out;
                clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
            }

            &.active,
            &:hover {
                &::after {
                    clip-path: polygon(0% 0, 100% 0, 100% 100%, 0% 100%);
                }
            }
        }
    }
}
