.trial-overview {
    &.trial-filter-expanded::after {
        @apply tw-opacity-50 tw-pointer-events-auto;
    }

    &::after {
        content: "";

        @apply tw-fixed tw-pointer-events-none tw-left-0 tw-top-0 tw-bg-black tw-opacity-0 tw-transition-opacity tw-duration-300 tw-ease-in-out tw-z-10 tw-w-full tw-h-full;
    }
}
