@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'main/import-fonts';
@import 'main/base';
@import 'main/buttons';
@import 'main/links';
@import 'main/lists';
@import 'main/detail';
@import 'main/media';
@import 'main/components';
@import 'main/form';
@import 'main/wysiwyg';
@import 'main/video';
@import 'main/clip-path';
@import 'main/pagination';
@import 'main/link-fill';

