.ProductDetail {
  .related-products {
    @apply tw-relative;

    @media screen and (min-width: theme('screens.md')) {
      &::after {
        @apply tw-block tw-absolute tw-top-0 tw-left-1/2 tw-bottom-0 tw--translate-x-1/2 tw-border-r tw-border-gray-800/20 tw-w-px;
        
        content: '';
      }
    }
  }
}